import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { CommonLibModule } from '@fc-lib';
import { GetStartedComponent } from './components/get-started/get-started.component';
import { YourDetailsComponent } from './components/your-details/your-details.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoaderComponent } from './components/loader/loader.component';
import { EmergencyContactComponent } from './components/emergency-contact/emergency-contact.component';
import { SignWaiverComponent } from './components/sign-waiver/sign-waiver.component';
import { CompletedComponent } from './components/completed/completed.component';
import { NgHeroiconsModule } from '@dimaslz/ng-heroicons';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { environment } from '../environments/environment';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ErrorToasterComponent } from './components/toasters/error-toaster/error-toaster.component';
import { SuccessToasterComponent } from './components/toasters/success-toaster/success-toaster.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { CUSTOM_DATE_FORMATS } from 'projects/admin/src/app/lib/date-format';

@NgModule({
  declarations: [
    AppComponent,
    GetStartedComponent,
    YourDetailsComponent,
    FooterComponent,
    LoaderComponent,
    EmergencyContactComponent,
    SignWaiverComponent,
    CompletedComponent,
    SuccessToasterComponent,
    ErrorToasterComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonLibModule,
    NgHeroiconsModule,
    FormsModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    AuthModule.forRoot({
      domain: environment.auth0Domain,
      clientId: environment.auth0ClientId,
      authorizationParams: {
          audience: 'fightcloud',
          redirect_uri: window.location.origin,
      },
      httpInterceptor: {
          allowedList: [
              '*'
          ]
      }
  })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true }, 
    provideHttpClient(withInterceptorsFromDi()),    
    { provide: MAT_DATE_LOCALE, useValue: 'en-AU' },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    MatDatepickerModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
