import {
  Component,
  Input,
  Optional,
  Self,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'fc-select-input',
  templateUrl: './select-input.component.html',
  styleUrls: ['./select-input.component.css'],
})
export class SelectInputComponent implements ControlValueAccessor, OnChanges {
  @Input() label: string | undefined;
  @Input() placeholder: string = 'Select an option...';
  @Input() required: boolean = false;
  @Input() helper: string | undefined;
  @Input() options: any[] = [];
  @Input() labelKey: string = 'name';
  @Input() valueKey: string = 'id';
  @Input() submitted: boolean = false;
  @Input() errorMessages: { [key: string]: string } = {};
  @Input() hideSingleSelectionIndicator: boolean = true;
  @Input() multiple: boolean = false;
  @Output() selectionChange = new EventEmitter<any>();

  selectedLabel: string | null = null;
  selectedLabels: string[] | null = null;
  isDisabled: boolean = false;

  currentValue: any;

  // Default error messages
  defaultErrorMessages: { [key: string]: string } = {
    required: 'This field is required.',
  };

  constructor(@Optional() @Self() public ngControl: NgControl) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['options'] || changes['currentValue']) {
      this.updateSelectedLabels();
    }
  }

  get isInvalid(): boolean {
    return this.submitted && (this.ngControl?.invalid ?? false);
  }

  get inputClasses(): string[] {
    const baseClasses = [
      'w-full', 'text-sm', 'border', 'focus:outline-0', 'focus:ring', 'pr-4', 'py-[10px]', 'rounded-lg', 'hover:bg-gray-50',
    ];

    if (this.isDisabled) {
      baseClasses.push('bg-gray-50', 'border-gray-300', 'text-gray-600');
    } else if (this.isInvalid) {
      baseClasses.push('border-error-300', 'focus:border-error-300', 'focus:ring-error-300');
    } else {
      baseClasses.push('border-gray-300', 'focus:border-primary-300', 'focus:ring-gray-100');
    }

    return baseClasses;
  }

  writeValue(value: any): void {
    this.currentValue = this.multiple ? value || [] : value;
    this.updateSelectedLabels();
  }

  private updateSelectedLabels(): void {
    if (this.multiple) {
        const selectedOptions = this.options.filter((item) =>
            (this.currentValue || []).includes(item[this.valueKey])
        );
        this.selectedLabels = selectedOptions.map(
            (option) => option[this.labelKey]
        );

        this.selectedLabel = this.selectedLabels.join(', ');
    } else {
        const selectedOption = this.options.find(
            (item) => item[this.valueKey] === this.currentValue
        );
        this.selectedLabel = selectedOption ? selectedOption[this.labelKey] : null;
    }
}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onSelectionChange(event: any): void {
    const value = event.value;
    this.currentValue = value;

    if (this.multiple) {
        const selectedOptions = this.options.filter((item) =>
            (value || []).includes(item[this.valueKey])
        );
        this.selectedLabels = selectedOptions.map(
            (option) => option[this.labelKey]
        );
        this.onChange(value);
    } else {
        const selectedOption = this.options.find(
            (item) => item[this.valueKey] === value
        );
        this.selectedLabel = selectedOption ? selectedOption[this.labelKey] : null;
        this.onChange(value);
    }

    this.selectionChange.emit(value);
}

  getActiveErrorMessages(): string[] {
    const errors = this.ngControl?.control?.errors || null;
    const mergedMessages = { ...this.defaultErrorMessages, ...this.errorMessages };
    if (!errors) return [];

    return Object.entries(errors).map(
      ([key]) => mergedMessages[key] || `Error: ${key}`
    );
  }

  private onChange = (value: any) => {};
  private onTouched = () => {};
}
