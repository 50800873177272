<div class="w-full">
    <label *ngIf="label" class="block mb-2 text-sm font-medium text-gray-700">{{ label }} <span class="text-primary-700" *ngIf="required">*</span></label>
    <div class="relative" [ngClass]="{'mb-2': helper}">
        <div *ngIf="iconComponent" class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <ng-content></ng-content>
        </div>
        <input *ngIf="matDatepicker"
            [type]="type"
            [ngClass]="inputClasses"
            [placeholder]="placeholder"
            [value]="value"
            [disabled]="isDisabled"
            [matDatepicker]="matDatepicker"
            (dateChange)="dateChange.emit($event)"
            (input)="onInputChange($event)"
        />
        <input *ngIf="!matDatepicker"
            [type]="type"
            [ngClass]="inputClasses"
            [placeholder]="placeholder"
            [value]="value"
            [disabled]="isDisabled"
            (input)="onInputChange($event)"
        />
    </div>
    <span *ngIf="helper" class="text-sm text-gray-600 mt-2">{{helper}}</span>
    <ng-container *ngIf="submitted">
        <span *ngFor="let errorMessage of getActiveErrorMessages()" class="block mt-2 text-sm text-error-500">{{ errorMessage }}</span>
    </ng-container>
</div>