<div class="w-full flex h-dvh">
    <div>
        <div class="p-4 h-full">
            <div class="h-full rounded-lg overflow-hidden">
                <img class="h-full" [src]="imageUrl"/>
            </div>
        </div>
    </div>
    <div class="grow flex flex-col gap-4 justify-center items-center">
        <img class="max-h-24" (load)="this.onLoadCompleted.emit()" [src]="logoUrl"/>
        <span class="text-3xl text-gray-900 font-semibold mt-4">
            Get started!
        </span>
        <span class="text-base text-gray-600 font-normal mb-4">
            Create a profile and sign a waiver.
        </span>
        <fc-button-primary (click)="onNext.emit()" variant="large" class="w-96">Get started!</fc-button-primary>
    </div>
</div>
    