<div class="w-full flex flex-col h-dvh">
    <div class="mx-[20%] mb-4">
        <fc-stepper [steps]="steps"></fc-stepper>
    </div>
    <div class="flex flex-1 min-h-0">
        <div>
            <div class="p-4 h-full">
                <div class="h-full rounded-lg overflow-hidden">
                    <img class="h-full" [src]="imageUrl"/>
                </div>
            </div>
        </div>
        <div class="flex-1 flex flex-col min-h-0">
            <div class="flex-1 overflow-y-auto px-8 pt-4 mb-5">
                <form [formGroup]="emergencyContactForm" class="flex flex-col gap-4">
                    <div class="font-semibold text-3xl text-gray-900 pb-4">Emergency contact</div>
                    <div class="font-semibold text-lg text-gray-900">Contact details</div>
                    <div class="text-gray-600 text-sm border-b border-gray-200 pb-6">Who should we contact in case of an emergency?</div>
                    <div class="flex gap-4 items-end border-b border-gray-200 mt-4 pb-4">
                        <fc-text-input class="grow" formControlName="emergencyContactFirstName" label="Emergency contact name" [required]="true" placeholder="First name" [submitted]="submitted"></fc-text-input>
                        <fc-text-input class="grow" formControlName="emergencyContactLastName" placeholder="Last name" [submitted]="submitted"></fc-text-input>
                    </div>
                    <div class="flex gap-4 items-end border-b border-gray-200 mt-2 pb-4">
                        <fc-text-input class="grow" formControlName="emergencyContactRelationship" label="Relationship" [required]="true" placeholder="Relationship" helper="Example: spouse, mother, step-dad" [submitted]="submitted"><user-group-outline-icon #icon /></fc-text-input>
                    </div>
                    <div class="flex gap-4 items-end border-b border-gray-200 mt-2 pb-4">
                        <fc-text-input class="grow" formControlName="emergencyContactMobile" label="Emergency contact number" [required]="true" placeholder="Contact number" icon="call" [submitted]="submitted"><phone-outline-icon #icon /></fc-text-input>
                    </div>
                    <div class="flex gap-4 items-end mt-2">
                        <fc-text-input class="grow" formControlName="emergencyContactEmail" label="Emergency contact email address" [required]="false" placeholder="Email address" icon="mail" [submitted]="submitted"><envelope-outline-icon #icon /></fc-text-input>
                    </div>
                </form>
            </div>
            <app-footer (onBack)="onBack.emit()" (onNext)="onSubmit()"></app-footer>
        </div>
    </div>
</div>