import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-completed',
  templateUrl: './completed.component.html',
  styleUrl: './completed.component.scss'
})
export class CompletedComponent implements OnInit {
  @Output() completed = new EventEmitter();

  ngOnInit(): void {
    setTimeout(() => this.completed.emit(), 60000);
  }
}
