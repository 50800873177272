import { Component } from '@angular/core';
import { Step } from '@fc-lib';
import { StudentCreate, StudentDetails, StudentEmergencyContact } from './models/student-create.model';
import { forkJoin, Observable } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';
import { StudentService } from './services/student.service';
import { GymService } from './services/gym.service';
import { Waiver } from './models/waiver.model';
import { ToasterService } from './services/toaster.service';
import { ToasterType } from './models/toaster.model';
import * as moment from 'moment';
import { Signature } from './models/signature.model';

enum SignUpStep {
  GetStarted,
  YourDetails,
  EmergencyContact,
  SignWaiver,
  Completed
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'sign-up-kiosk';
  currentStep: SignUpStep = SignUpStep.GetStarted;
  signUpStep = SignUpStep;
  loading = true;
  studentCreateModel: Partial<StudentCreate> = {};
  studentDetails: StudentDetails | undefined;
  studentEmergencyContact: StudentEmergencyContact | undefined;
  logoUrl: string | undefined;
  getStartedImageUrl: string | undefined;
  studentDetailsImageUrl: string | undefined;
  emergencyContactImageUrl: string | undefined;
  waiver: Waiver | undefined;

  steps: Step[] = [
    { title: 'Your details', currentStep: false, completed: true },
    { title: 'Emergency contacts', currentStep: true, completed: false },
    { title: 'Sign a waiver', currentStep: false, completed: false },
  ]

  isAuthenticated$: Observable<boolean>;

  get guardianSignature() {
    if (this.studentDetails?.dateOfBirth) {
      return moment().diff(this.studentDetails?.dateOfBirth, 'years') < 18;
    }

    return false;
  }

  get studentName() {
    if (this.studentDetails?.firstName && this.studentDetails?.lastName) {
      return `${this.studentDetails?.firstName} ${this.studentDetails?.lastName}`;
    }

    return '';
  }

  constructor(private authService: AuthService, private studentService: StudentService, private gymService: GymService, private toasterService: ToasterService) {
    this.isAuthenticated$ = this.authService.isAuthenticated$;

    this.isAuthenticated$.subscribe(authenticated => {
      if (!authenticated) {
        this.authService.loginWithRedirect();
      }
    })

    forkJoin({
      gym: this.gymService.getGym(),
      settings: this.gymService.getGymAppSettings()
    }).subscribe((result) => {
      this.logoUrl = result.gym.logoUrl;
      this.getStartedImageUrl = result.settings.getStartedImageUrl;
      this.studentDetailsImageUrl = result.settings.studentDetailsImageUrl;
      this.emergencyContactImageUrl = result.settings.emergencyContactImageUrl;
      this.waiver = {
        waiverDocumentId: result.settings.waiverDocumentId,
        waiverName: result.settings.waiverName,
        waiverDescription: result.settings.waiverDescription,
        waiverContent: result.settings.waiverContent,
        waiverDate: result.settings.waiverDate
      }
      this.loading = false;
    });
  }

  onDetailsNext(details: StudentDetails) {
    this.studentDetails = details;
    this.studentCreateModel = { ...details };
    this.studentCreateModel.guardianSignature = this.guardianSignature;
    this.currentStep = SignUpStep.EmergencyContact;
  }

  onEmergencyContactNext(emergencyContact: StudentEmergencyContact) {
    this.studentEmergencyContact = emergencyContact;
    this.studentCreateModel = { ...this.studentCreateModel, ...emergencyContact};
    this.currentStep = SignUpStep.SignWaiver;
  }

  onSignWaiverNext(signature: Signature) {
    this.studentCreateModel.signatureUrl = signature.signatureUrl;
    this.studentCreateModel.signatureName = signature.signatureName;

    this.studentService.createStudent(this.studentCreateModel).subscribe({
      next: () => this.currentStep = SignUpStep.Completed,
      error: (error) => {
        this.toasterService.addToaster({message: error.error.message, type: ToasterType.Error});
      }
    })
  }

  onCompleted() {
    this.studentCreateModel = {};
    this.studentDetails = undefined
    this.studentEmergencyContact = undefined;
    this.currentStep = SignUpStep.GetStarted;
  }

  onNext(step: SignUpStep) {
    this.currentStep = step + 1;
  }

  onBack(step: SignUpStep) {
    this.currentStep = step - 1;
  }
}
