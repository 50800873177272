import { Component, Input } from '@angular/core';

@Component({
  selector: 'fc-button-secondary',
  templateUrl: './button-secondary.component.html',
})
export class ButtonSecondaryComponent {
  @Input() variant: string = '';

  get buttonClasses(): string[] {
    const baseClasses = [
      'bg-white', 'text-gray-700', 'border', 'shadow-sm', 'border-gray-300', 'hover:bg-gray-50', 'focus:ring', 'focus:ring-gray-100', 'focus:outline-0', 'font-semibold', 'rounded-lg', 'text-center', 'w-full', 'flex', 'items-center'
    ];

    if (this.variant == 'large') {
      baseClasses.push('px-4', 'py-2.5', 'font-semibold');
    }
    else {
      baseClasses.push('text-sm', 'px-3', 'py-2', 'font-medium', 'h-[30px]', 'sm:h-[38px]');
    }
    
    return baseClasses;
  }
}
