<div class="w-full">
    <label *ngIf="label" class="block mb-2 text-sm font-medium text-gray-700">
        {{ label }}
        <span class="text-primary-700" *ngIf="required">*</span>
    </label>

    <textarea [rows]="rows" [ngClass]="inputClasses" [placeholder]="placeholder" [disabled]="isDisabled"
        (input)="onInputChange($event)" [value]="value"></textarea>

    <span *ngIf="helper" class="text-sm text-gray-600 mt-2">{{ helper }}</span>

    <ng-container *ngIf="submitted && ngControl?.invalid">
        <span *ngFor="let errorMessage of getActiveErrorMessages()" class="block mt-2 text-sm text-error-500">
            {{ errorMessage }}
        </span>
    </ng-container>
</div>